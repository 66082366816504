import styled from "styled-components";
import {
  BP,
  Rem,
} from "../../commons/Theme";

export const StyledContactForm = styled.div`
  padding-top: ${Rem(0)};

  @media (${BP.ipad}) {
    padding-top: ${Rem(0)};
  }
`;

export const StyledContactFormSubmit = styled.div`
  position: relative;
  padding-top: ${Rem(30)};
  display: flex;
  justify-content: center;

  @media (${BP.ipad}) {
    padding-top: ${Rem(60)};
  }
`;
